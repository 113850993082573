import './App.css';
import * as React from 'react';
import Grid from '@mui/material/Grid';
import {Box, Typography} from "@mui/material"; // Grid version 1

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <Grid container spacing={2} sx={{backgroundColor: 'indigo'}}>
                    <Grid xs={12}>
                        <Box sx={{height: '150px'}}></Box>
                    </Grid>
                    <Grid xs={4}>
                        <Box sx={{height: '150px'}}></Box>
                    </Grid>
                    <Grid xs={4}>
                        <Box
                            component="img"
                            alt="2_Remarkable"
                            src={process.env.PUBLIC_URL + '/2_Remarkable.png'}
                        />
                        <Typography variant="caption">
                            2_Remarkable.png
                        </Typography>
                    </Grid>
                    <Grid xs={4}>
                        <Box
                            component="img"
                            alt="3_Practicas"
                            src={process.env.PUBLIC_URL + '/3_Practicas.png'}
                        />
                        <Typography variant="caption">
                            3_Practicas.png
                        </Typography>
                    </Grid>
                    <Grid xs={6}>
                        <Box
                            component="img"
                            alt="4_1_Necesidades"
                            src={process.env.PUBLIC_URL + '/4_1_Necesidades.png'}
                        />
                        <Typography variant="caption">
                            4_1_Necesidades.png
                        </Typography>
                    </Grid>
                    <Grid xs={4}>
                        <Box
                            component="img"
                            alt="5_Etica_geometrica_espirulina"
                            sx={{zIndex: 1600}}
                            src={process.env.PUBLIC_URL + '/5_Etica_geometrica_espirulina.png'}
                        />
                        <Typography variant="caption">
                            5_Etica_geometrica_espirulina.png
                        </Typography>
                    </Grid>
                    <Grid xs={3}>
                        <div></div>
                    </Grid>
                    <Grid xs={4}>
                        <Box
                            component="img"
                            alt="4_Necesidades_proyectos"
                            src={process.env.PUBLIC_URL + '/4_Necesidades_proyectos.png'}
                        />
                        <Typography variant="caption">
                        4_Necesidades_proyectos.png
                    </Typography>
                    </Grid>
                    <Grid xs={2}>
                        <Box sx={{height: '150px'}}></Box>
                    </Grid>
                    <Grid xs={3}>
                        <Box
                            component="img"
                            alt="1_Libreta_azul"
                            src={process.env.PUBLIC_URL + '/1_Libreta_azul.png'}
                        />
                        <Typography variant="caption">
                            1_Libreta_azul.png
                        </Typography>
                    </Grid>
                </Grid>
            </header>
        </div>
    );
}

export default App;
